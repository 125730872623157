import { usePostOfficeContext } from '@atlassian/post-office-context';

import { productNames } from './constants';

export const useIsWacContext = (): boolean => {
	const context = usePostOfficeContext();
	return context.product === productNames.WAC;
};

export const getAdminHubUrl = (microsEnvType: string): string => {
	switch (microsEnvType) {
		case 'dev':
			return 'https://admin.dev.atlassian.com';
		case 'staging':
			return 'https://admin.stg.atlassian.com';
		default:
			return 'https://admin.atlassian.com';
	}
};
