/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { token } from '@atlaskit/tokens';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import Item, { type SwitcherItemProps } from './item';

const itemStyles = css({
	padding: `${token('space.100', '8px')}`,
	borderRadius: token('border.radius', '4px'),
});

const iconStyles = css({
	marginRight: `${token('space.negative.050', '-4px')}`,
});

export default (props: SwitcherItemProps) => {
	const { icon, ...rest } = props;
	return <Item icon={<span css={iconStyles}>{icon}</span>} {...rest} css={itemStyles} />;
};
