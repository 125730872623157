import { type FlagProps } from '@atlaskit/flag';

import type { FlagsStateContainer } from '@confluence/flags';

const appearanceMappedToConfluenceFlagType = (appearance: FlagProps['appearance']) => {
	switch (appearance) {
		case 'normal':
		case 'success':
			return 'success-circle';
		default:
			return appearance;
	}
};

export const customShowFlag = (flags: FlagsStateContainer) => (flag: FlagProps) => {
	const { title, description, appearance, actions } = flag;
	void flags.showFlag({
		type: appearanceMappedToConfluenceFlagType(appearance),
		title,
		description,
		close: 'auto',
		actions,
	});
};
