import { AnyAri } from '@atlassian/ari';

import { type CollaborationGraphEntity } from '@atlassian/cross-joinable-products-in-switcher';

import { FetchError } from '../../common/utils/errors/fetch-error';
import {
	type JoinableProductDetails,
	type JoinableSite,
	type JoinableSitesResponse,
	type JoinableSiteUser,
	ProductKey,
	type ProductRecommendationsResponse,
	type ProductRecommendationResourceWithCapability,
	type JoinableSiteUsersKeyedByProduct,
} from '../../types';

export type CollaboratorsResponse = {
	[key: string]: CollaborationGraphEntity[];
};

/* Joinable Sites API was replaced by Product Recommendations API as part of productionizing the Joinable Sites
   experimental API. This code maps Product Recommendations API results into Joinable Sites API results for
   compatibility with existing UI elements.
 */
function productRecommendationsUrl(baseUrl: string): string {
	return (
		baseUrl +
		'/v1/product-recommendations' +
		'?product=jira-software&product=jira-servicedesk&product=confluence&product=jira-product-discovery'
	);
}

/*
	Experiment API endpoint used to get collaborators to provided products
	https://bitbucket.org/atlassian/experiment-api/src/master/src/experiments/cross-join-switcher/
*/
const collaboratorsUrl = '/gateway/api/growth/cross-join-switcher/get-eligible-collaborators';

// tenantedProducts and nonTenantedProducts used to identify which ARIs contain cloudId
// Also used to convert resourceOwner strings to JoinableSites product strings
const supportedTenantedProducts: Record<string, string> = {
	confluence: ProductKey.CONFLUENCE,
	'jira-software': ProductKey.JIRA_SOFTWARE,
	'jira-product-discovery': ProductKey.JIRA_PRODUCT_DISCOVERY,
	'jira-servicedesk': ProductKey.JIRA_SERVICE_DESK,
};

const tenantedProductsToProduct: Record<string, string> = {
	[ProductKey.CONFLUENCE]: 'confluence',
	[ProductKey.JIRA_SOFTWARE]: 'jira-software',
	[ProductKey.JIRA_PRODUCT_DISCOVERY]: ProductKey.JIRA_PRODUCT_DISCOVERY,
	[ProductKey.JIRA_SERVICE_DESK]: 'jira-servicedesk',
};

function handleCapabilities(capabilities: ProductRecommendationResourceWithCapability[]) {
	return capabilities
		?.filter((inputResource) => {
			const parsedAri = AnyAri.parse(inputResource.resourceId);
			return (
				supportedTenantedProducts[parsedAri.resourceOwner] && parsedAri.resourceType === 'site'
			);
		})
		.reduce(
			(
				sites: Record<string, JoinableSite>,
				inputValue: ProductRecommendationResourceWithCapability,
				index,
			) => {
				const ari = AnyAri.parse(inputValue.resourceId);
				const sitesProducts = sites[ari.resourceId]?.products;
				if (sitesProducts) {
					sitesProducts[supportedTenantedProducts[ari.resourceOwner]] = {
						collaborators: [] as JoinableSiteUser[],
						productUrl: inputValue.url,
						capability: inputValue.capability,
					};
					return sites;
				}

				try {
					// Add site with this product
					const url = new URL(inputValue.url);

					const products: Record<string, JoinableProductDetails> = {};
					products[supportedTenantedProducts[ari.resourceOwner]] = {
						collaborators: [] as JoinableSiteUser[],
						productUrl: inputValue.url,
						capability: inputValue.capability,
					};

					sites[ari.resourceId] = {
						cloudId: ari.resourceId,
						url: url.origin,
						displayName: inputValue.displayName,
						products,
						relevance: Math.max(1000 - index, 0),
					};
					return sites;
				} catch (e) {
					return sites;
				}
			},
			{} as Record<string, JoinableSite>,
		);
}

function convertProductRecommendationsResponseToJoinableSitesResponse(
	input: ProductRecommendationsResponse,
): JoinableSitesResponse {
	const joinedCapabilities = handleCapabilities(
		input.capability?.DIRECT_ACCESS.map((site) => ({
			...site,
			capability: 'DIRECT_ACCESS',
		})).concat(
			input.capability?.REQUEST_ACCESS.map((site) => ({
				...site,
				capability: 'REQUEST_ACCESS',
			})),
		) as ProductRecommendationResourceWithCapability[],
	);

	return {
		sites: Object.values(joinedCapabilities),
	};
}

function isPublicEmailDomainError(body: any): boolean {
	// Public Email Domain Error returns a body with the following content:
	// {"code":"email-public-domain","message":"Request requires a domain which is not public"}
	return body?.code === 'email-public-domain';
}

const emptyProductRecommendationResponse: ProductRecommendationsResponse = {
	capability: { DIRECT_ACCESS: [], REQUEST_ACCESS: [] },
};

/* We are unable to use fetchJson from packages/navigation/atlassian-switcher/src/common/utils/fetch.ts
 * because some 400 errors from productRecommendation API are expected and should be handled as empty data
 */
export const fetchJsonOrEmptyProductRecommendationsResponse = (
	url: string,
	init?: RequestInit,
): Promise<ProductRecommendationsResponse> =>
	fetch(url, { credentials: 'include', ...init })
		.then(async (response) => {
			const jsonPromise = response.json();
			if (response.ok) {
				return jsonPromise;
			}
			if (response.status === 400) {
				const json = await jsonPromise;
				if (isPublicEmailDomainError(json)) {
					return emptyProductRecommendationResponse;
				}
			}
			throw new FetchError(
				`Unable to fetch ${url} ${response.status} ${response.statusText}`,
				response.status,
			);
		})
		.catch(() => {
			throw new FetchError('Network Error', undefined);
		});

export async function fetchProductRecommendationsWithCollaboratorsInternal(
	cloudId: string = '',
	baseUrl: string = '',
): Promise<JoinableSitesResponse> {
	const response = await fetchJsonOrEmptyProductRecommendationsResponse(
		productRecommendationsUrl(baseUrl),
		{
			method: 'get',
		},
	);

	const sitesResponse = convertProductRecommendationsResponseToJoinableSitesResponse(response);

	if (!cloudId) {
		return sitesResponse;
	}
	const filteredSites = sitesResponse?.sites?.filter((site) => site.cloudId === cloudId);

	// This mapping is required for calling the get-eligible-collaborators endpoint
	const flattenedProducts = filteredSites?.flatMap<string[]>((site) =>
		Object.keys(site.products || []).map((productName) => tenantedProductsToProduct[productName]),
	);

	if (!filteredSites || filteredSites?.length === 0 || flattenedProducts?.length === 0) {
		return { sites: filteredSites };
	}
	const collaboratorsResponse: Response = await fetch(collaboratorsUrl, {
		method: 'POST',
		body: JSON.stringify({ products: flattenedProducts, cloudId }),
		headers: {
			'Content-Type': 'application/json',
		},
	});

	if (!collaboratorsResponse.ok) {
		return { sites: filteredSites };
	}

	const collaboratorsJson: CollaboratorsResponse = await collaboratorsResponse.json();

	// Renames key with tenantedProducts and filters out empty responses
	const updatedProductCollaborators = Object.fromEntries(
		Object.entries(collaboratorsJson)
			.filter((item) => Array.isArray(item[1]) && item[1]?.length !== 0)
			.map((item) => {
				return [
					supportedTenantedProducts[item[0]] || item[0],
					item[1].map(
						({ userProfile }) =>
							({
								displayName: userProfile?.name || '',
								avatarUrl: userProfile?.picture || '',
							}) as JoinableSiteUser,
					),
				];
			}),
	);

	const updatedProductCollaboratorsKeys = Object.keys(updatedProductCollaborators);

	// Inject collaborators with each related product
	// Inject users with each site.
	const sitesWithCollaborators = filteredSites.map((site) => {
		updatedProductCollaboratorsKeys.forEach((collaboratorProductKey) => {
			if (site?.products?.[collaboratorProductKey]) {
				(site.products[collaboratorProductKey] as JoinableProductDetails).collaborators =
					updatedProductCollaborators[collaboratorProductKey];
			}
		});
		site.users = Object.keys(site?.products || {}).reduce((users, product) => {
			users[product] = updatedProductCollaborators[product] || [];
			return users;
		}, {} as JoinableSiteUsersKeyedByProduct);
		return site;
	});
	const updatedSiteResponse: JoinableSitesResponse = {
		sites: sitesWithCollaborators,
	};

	return updatedSiteResponse;
}

export const fetchProductRecommendationsWithCollaborators =
	(baseUrl: string) =>
	({ cloudId }: { cloudId?: string }) =>
		fetchProductRecommendationsWithCollaboratorsInternal(cloudId, baseUrl);
