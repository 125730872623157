/*
# This file was automatically generated using "yarn generate:all" command.
# Please do not edit it manually.
# These imports are for client side use only
#
# Generate source: local/cli/src/commands/placements/generate-placement-files.ts
*/

import { asMessageRenderer } from '@post-office/placement-common';
import growth from '@post-office/team-export--growth/placements/in-app/app-switcher-discovery-section/render';
import postOfficeTemplate from '@post-office/team-export--post-office-template/placements/in-app/app-switcher-discovery-section/render';
import testMessages from '@post-office/team-export--test-messages/placements/in-app/app-switcher-discovery-section/render';

export const renderer = { ...growth, ...postOfficeTemplate, ...testMessages };

export const { initializeMessageRenderer, MessageRenderer } = asMessageRenderer(renderer);
