import * as colors from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { SwitcherItem } from '@atlassian/switcher/primitives';
import { css } from '@compiled/react';

const iconSkeletonStyles = css({
	display: 'inline-block',
	width: 32,
	height: 32,
	backgroundColor: token('color.skeleton', colors.N20),
	borderRadius: token('border.radius.200', '8px'),
});

const lineSkeletonStyles = css({
	display: 'inline-block',
	width: 98,
	height: 10,
	backgroundColor: token('color.skeleton', colors.N20),
	borderRadius: token('border.radius', '3px'),
});

const iconSkeleton = <div css={iconSkeletonStyles} />;
const lineSkeleton = <div css={lineSkeletonStyles} />;

// TODO: Export this as a primitive from Switcher instead of creating it here
export const SwitcherSkeletonItem = () => {
	return (
		<SwitcherItem tag="li" icon={iconSkeleton} isDisabled>
			{lineSkeleton}
		</SwitcherItem>
	);
};
