import { AutoDismissFlag, FlagGroup } from '@atlaskit/flag';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';
import * as colors from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useFirePostOfficeAnalyticsEvents } from '@post-office/analytics';
import { useIsWacContext } from '@post-office/growth-common';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { WacToast } from './wac-toast';
import { removeMessage } from '../utils/optimisticUpdateHelpers';

type ProductDismissalFlagProps = {
	dismissedMessageIds: string[];
	handleDismissFlag: (messageId: string) => void;
};

// /**--Title--**/

const Title = () => (
	<FormattedMessage
		id="growth.app-switcher-discovery-section.flag.title"
		defaultMessage="Got it, thanks for letting us know"
		description="The title for a flag which is displayed when the Not Interested button is pressed."
	/>
);

// /**--Description--**/

const Description = () => (
	<FormattedMessage
		id="growth.app-switcher-discovery-section.flag.description"
		defaultMessage="You won’t see this here as often."
		description="The subtitle for a flag which is displayed when the Not Interested button is pressed."
	/>
);

export const ProductDismissalFlag = ({
	dismissedMessageIds,
	handleDismissFlag,
}: ProductDismissalFlagProps) => {
	const { fireAnalyticsEvent } = useFirePostOfficeAnalyticsEvents();
	const isInWacContext = useIsWacContext();

	const onDismissed = (messageId: string) => {
		fireAnalyticsEvent({
			eventType: 'ui',
			action: 'clicked',
			actionSubject: 'button',
			actionSubjectId: 'adControlsFlagClose',
		});
		handleDismissFlag(messageId);
	};

	return (
		<FlagGroup>
			{dismissedMessageIds.map((messageId) =>
				isInWacContext ? (
					<WacToast
						key={messageId}
						title={<Title />}
						description={<Description />}
						onDismissed={() => onDismissed(messageId)}
					/>
				) : (
					<AutoDismissFlag
						icon={
							<CheckCircleIcon
								primaryColor={token('color.icon.success', colors.G400)}
								label="Success"
							/>
						}
						description={<Description />}
						id={messageId}
						key={messageId}
						title={<Title />}
						appearance={'normal'}
						onDismissed={() => onDismissed(messageId)}
					/>
				),
			)}
		</FlagGroup>
	);
};

type UseProductDismissal = {
	dismissedMessageIds: string[];
	showFlag: (dismissedMessageId: string) => void;
	handleDismissFlag: (dismissedMessageId: string) => void;
};

export const useProductDismissalFlag = (): UseProductDismissal => {
	const [dismissedMessageIds, setDismissedMessageIds] = useState<string[]>([]);

	return {
		dismissedMessageIds,
		showFlag: (dismissedMessageId) =>
			setDismissedMessageIds((current) => [...current, dismissedMessageId]),
		handleDismissFlag: (dismissedMessageId: string) =>
			setDismissedMessageIds(removeMessage(dismissedMessageId)),
	};
};
