/*
# This file was automatically generated using "yarn generate:all" command.
# Please do not edit it manually.
# These exports are for client side use only
# Importing into post office backend without a bundler will break the build
#
# Generate source: local/cli/src/commands/generate-all/team-export.ts
*/
import { lazy } from "react";

export default {
	'recommendation-atlas': lazy(
			() =>
			import(
				/* webpackChunkName: "[request]" */
				'@post-office/message-template--recommendation-atlas/placements/in-app/app-switcher-discovery-section/render'
			),
	),
	'recommendation-confluence': lazy(
			() =>
			import(
				/* webpackChunkName: "[request]" */
				'@post-office/message-template--recommendation-confluence/placements/in-app/app-switcher-discovery-section/render'
			),
	),
	'recommendation-jpd': lazy(
			() =>
			import(
				/* webpackChunkName: "[request]" */
				'@post-office/message-template--recommendation-jpd/placements/in-app/app-switcher-discovery-section/render'
			),
	),
	'recommendation-jsm': lazy(
			() =>
			import(
				/* webpackChunkName: "[request]" */
				'@post-office/message-template--recommendation-jsm/placements/in-app/app-switcher-discovery-section/render'
			),
	),
	'recommendation-jsw': lazy(
			() =>
			import(
				/* webpackChunkName: "[request]" */
				'@post-office/message-template--recommendation-jsw/placements/in-app/app-switcher-discovery-section/render'
			),
	),
	'recommendation-jsw-dynamic-description': lazy(
			() =>
			import(
				/* webpackChunkName: "[request]" */
				'@post-office/message-template--recommendation-jsw-dynamic-description/placements/in-app/app-switcher-discovery-section/render'
			),
	),
	'recommendation-jwm': lazy(
			() =>
			import(
				/* webpackChunkName: "[request]" */
				'@post-office/message-template--recommendation-jwm/placements/in-app/app-switcher-discovery-section/render'
			),
	),
	'recommendation-loom': lazy(
			() =>
			import(
				/* webpackChunkName: "[request]" */
				'@post-office/message-template--recommendation-loom/placements/in-app/app-switcher-discovery-section/render'
			),
	),
	'recommendation-opsgenie': lazy(
			() =>
			import(
				/* webpackChunkName: "[request]" */
				'@post-office/message-template--recommendation-opsgenie/placements/in-app/app-switcher-discovery-section/render'
			),
	),
	'recommendation-rovo': lazy(
			() =>
			import(
				/* webpackChunkName: "[request]" */
				'@post-office/message-template--recommendation-rovo/placements/in-app/app-switcher-discovery-section/render'
			),
	),
};
